import React from 'react';
import styled from 'styled-components';
import { HeaderFirst } from 'components/header-first';
import { HeaderSecond } from 'components/header-second';
import { bool, node, string } from 'prop-types';

const SHeaderWithLine = styled(HeaderSecond)`
    position: relative;
    text-align: center;
    color: var(--black-text-color);
    margin: 4rem 0;
    word-break: break-word;
    ${(props) =>
        props.lineLeft &&
        `    
        padding: 0;
        margin: 0 0 2rem;
        text-align: left;
    `}
    &::before {
        content: '';
        width: 2.5rem;
        max-width: 100%;
        height: 0.1875rem;
        background-color: ${({ lineColor }) =>
            lineColor || 'var(--black-text-color)'};
        position: absolute;
        bottom: -2.1875rem;
        left: 50%;
        transform: translateX(-50%);
        ${(props) =>
            props.lineTop &&
            `
            bottom: none;
            top: -1.5625rem;
        `}
        ${(props) =>
            props.lineLeft &&
            `
            left: 0;
            transform: none;
        `}
        ${(props) =>
            props.lineRight &&
            `
            left: unset;
            right: 0;
            transform: none;
        `}
    }
`;

const SHeaderFirstWithLine = styled(HeaderFirst)`
    margin-bottom: 4rem;
    text-align: center;
    position: relative;
    &::before {
        content: '';
        width: 2.5rem;
        max-width: 100%;
        height: 0.1875rem;
        background-color: ${({ lineColor }) =>
            lineColor || 'var(--black-text-color)'};
        position: absolute;
        bottom: -2.1875rem;
        left: 50%;
        transform: translateX(-50%);
        ${(props) =>
            props.lineTop &&
            `
            bottom: none;
            top: -1.5625rem;
        `}
        ${(props) =>
            props.lineLeft &&
            `
            left: 0;
            transform: none;
        `}
        ${(props) =>
            props.lineRight &&
            `
            left: unset;
            right: 0;
            transform: none;
        `}
    }
`;

export const HeaderWithLine = ({
    children,
    lineTop,
    lineLeft,
    lineRight,
    className,
    isH1,
    lineColor,
}) => (
    <>
        {isH1 ? (
            <SHeaderFirstWithLine
                className={className}
                lineTop={lineTop}
                lineLeft={lineLeft}
                lineRight={lineRight}
                lineColor={lineColor}
            >
                {children}
            </SHeaderFirstWithLine>
        ) : (
            <SHeaderWithLine
                className={className}
                lineTop={lineTop}
                lineLeft={lineLeft}
                lineRight={lineRight}
                lineColor={lineColor}
            >
                {children}
            </SHeaderWithLine>
        )}
    </>
);

HeaderWithLine.propTypes = {
    className: string,
    children: node.isRequired,
    lineTop: bool,
    lineLeft: bool,
    lineRight: bool,
    isH1: bool,
    lineColor: string,
};

HeaderWithLine.defaultProps = {
    className: '',
    lineTop: false,
    lineLeft: false,
    lineRight: false,
    isH1: false,
    lineColor: '',
};
