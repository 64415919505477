import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Container } from 'components/container';
import { HeaderSecond } from 'components/header-second';
import { HeaderThird } from 'components/header-third';
import { MainTitle } from 'components/main-title';
import { PageBackground } from 'components/page-background';
import { CONSTANTS } from 'constants/styles/constants';
import { graphql, useStaticQuery } from 'gatsby';

import { Flags } from './components/Flags';
import { Fund } from './components/Fund';

const SFundsEuView = styled.div`
    margin-top: ${CONSTANTS.MARGIN.PAGE};
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const SSubTitle = styled(HeaderSecond)`
    margin-bottom: 2rem;
    text-align: center;
`;

const SParagraph = styled(HeaderThird)`
    text-align: center;
    padding: 0 2rem;
    color: var(--simple-gray-color);
`;

const SFundsWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
`;

export const FundsEuView = () => {
    const images = useStaticQuery(graphql`
        {
            background: file(
                relativePath: { eq: "backgrounds/bg-european-funds.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: FULL_WIDTH
                    )
                }
            }
            canada: file(relativePath: { eq: "icons/funds-eu/ic-canada.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 90
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
            effects: file(
                relativePath: { eq: "icons/funds-eu/ic-effects.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        width: 90
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
            budget: file(relativePath: { eq: "icons/funds-eu/ic-budget.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 90
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
        }
    `);
    const funds = [
        {
            image: images.canada,
            title: 'funds.title1',
            text: 'funds.text1',
            alt: 'canada',
        },
        {
            image: images.effects,
            title: 'funds.title2',
            text: 'funds.text2',
            alt: 'effects',
        },
        {
            image: images.budget,
            title: 'funds.title3',
            text: 'funds.text3',
            alt: 'budget',
        },
    ];

    const fundsToRender = funds.map((fund, index) => (
        <Fund key={index} fund={fund} />
    ));
    return (
        <SFundsEuView>
            <PageBackground
                childImageSharp={images.background.childImageSharp}
                alt="Funds Eu Page Background"
            />
            <Container>
                <MainTitle>
                    <FormattedMessage id="funds.europeanFunds" />
                </MainTitle>
                <SSubTitle>
                    <FormattedMessage id="funds.company" />
                </SSubTitle>
                <SParagraph>
                    <FormattedMessage id="funds.mobileReality" />
                </SParagraph>
            </Container>
            <Container>
                <SFundsWrapper>{fundsToRender}</SFundsWrapper>
            </Container>
            <Container>
                <Flags />
            </Container>
        </SFundsEuView>
    );
};
