import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { HeaderWithLine } from 'components/header-with-line';
import { NonStretchedImage } from 'components/non-stretched-image';
import { Paragraph } from 'components/paragraph';
import { CONSTANTS } from 'constants/styles/constants';
import { object, shape, string } from 'prop-types';

const SFund = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 22.5rem;
    text-align: center;
    padding: 1rem;
    margin: 1rem;
    ${CONSTANTS.MEDIA.max600`
        width: 100%;
    `};
`;

const SHeaderWithLine = styled(HeaderWithLine)`
    margin: 2rem 0 4rem;
`;

const SImg = styled(NonStretchedImage)`
    width: ${(props) => props.width}px;
    height: auto;
`;

export const Fund = (props) => {
    const {
        fund: { title, text, image, alt },
    } = props;
    return (
        <SFund>
            <SImg
                childImageSharp={image.childImageSharp}
                width={image.childImageSharp.gatsbyImageData.presentationWidth}
                alt={alt}
            />
            <SHeaderWithLine>
                <FormattedMessage id={title} />
            </SHeaderWithLine>
            <Paragraph>
                <FormattedMessage id={text} />
            </Paragraph>
        </SFund>
    );
};

Fund.propTypes = {
    fund: shape({
        title: string,
        text: string,
        image: object,
        alt: string,
    }).isRequired,
};
