import React from 'react';
import styled from 'styled-components';
import { NonStretchedImage } from 'components/non-stretched-image';
import { graphql, useStaticQuery } from 'gatsby';

const SFlags = styled.div`
    width: 100%;
    min-height: 25vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    border: 1px solid var(--warm-gray-five-color);
`;

const SImg = styled(NonStretchedImage)`
    width: auto;
    height: auto;
    margin: 2rem 4rem;
`;

export const Flags = () => {
    const images = useStaticQuery(graphql`
        {
            fundsEu: file(relativePath: { eq: "icons/funds-eu/ic-funds.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 176
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
            flagPL: file(relativePath: { eq: "icons/funds-eu/ic-poland.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 262
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
            flagEU: file(
                relativePath: { eq: "icons/funds-eu/ic-european-union.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        width: 262
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
        }
    `);
    const flags = [
        {
            image: images.fundsEu,
            alt: 'european union funds',
        },
        {
            image: images.flagPL,
            alt: 'poland',
        },
        {
            image: images.flagEU,
            alt: 'european union',
        },
    ];
    const flagsToRender = flags.map((flag, index) => (
        <SImg
            key={index}
            childImageSharp={flag.image.childImageSharp}
            alt={flag.alt}
        />
    ));
    return <SFlags>{flagsToRender}</SFlags>;
};
