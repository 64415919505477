import React from 'react';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import messages from 'constants/translations/funds-eu.json';
import Layout from 'layouts/standard';
import { object } from 'prop-types';

import { FundsEuView } from '../views/funds-eu';

const FundsEuPage = (props) => {
    const { location } = props;
    return (
        <Layout location={location} messages={messages}>
            <FundsEuView />
        </Layout>
    );
};

FundsEuPage.propTypes = {
    location: object.isRequired,
};

export default FundsEuPage;

export const Head = () => <SEO tags={TAGS.FUNDS_EU} />;
